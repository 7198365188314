.col-span-10 {
    grid-column: span 10 / span 10;
}

.col-start-2 {
    grid-column-start: 2;
}

.flex {
    display: flex;
}

.grow {
    flex-grow: 1;
}

.grid {
    display: grid;
}

.grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.auto-cols-auto {
    grid-auto-columns: auto;
}

.grid-flow-col {
    grid-auto-flow: column;
}

.auto-cols-max {
    grid-auto-columns: max-content;
}

.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.items-center {
    align-items: center;
}

.items-baseline {
    align-items: baseline;
}

.flex-1 {
    flex: 1 1 0%;
}

.gap-1 {
    gap: 0.25rem;
}

.gap-2 {
    gap: 0.5rem;
}

.gap-2\.5 {
    gap: 0.625rem;
}

.gap-5 {
    gap: 1.25rem;
}

.self-center {
    align-self: center;
}

.text-center {
    text-align: center;
}

// Cleanup
.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

// Cleanup
@media (min-width: 640px) {
    .sm\:col-span-10 {
        grid-column: span 10 / span 10;
    }

    .sm\:col-start-2 {
        grid-column-start: 2;
    }
}

@media (min-width: 768px) {
    .md\:col-span-8 {
        grid-column: span 8 / span 8;
    }

    .md\:col-start-3 {
        grid-column-start: 3;
    }
}

@media (min-width: 1024px) {
    .lg\:col-span-6 {
        grid-column: span 6 / span 6;
    }

    .lg\:col-start-4 {
        grid-column-start: 4;
    }
}

@media (min-width: 1280px) {
    .xl\:col-span-6 {
        grid-column: span 6 / span 6;
    }

    .xl\:col-start-4 {
        grid-column-start: 4;
    }
}