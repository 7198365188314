@font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(./assets/fonts/metropolis/Metropolis-Regular.woff2) format('woff2');
}

@font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(./assets/fonts/metropolis/Metropolis-Medium.woff2) format('woff2');
}

@font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(./assets/fonts/metropolis/Metropolis-SemiBold.woff2) format('woff2');
}

@font-face {
    font-family: 'Metropolis';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(./assets/fonts/metropolis/Metropolis-Bold.woff2) format('woff2');
}