// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "sass:map";
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use './fonts.scss';
@use './material-styles-override.scss' as matStyleOverrides;
@use './grid.scss';
// Own color palette
@use './palette.scss' as palette;
@use './bay-first-theme' as bayfirst;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$BayFirst-primary: mat.m2-define-palette(palette.$primary, 800);
$BayFirst-accent: mat.m2-define-palette(palette.$accent, 500);

// The warn palette is optional (defaults to red).
$BayFirst-warn: mat.m2-define-palette(palette.$warn);

$label: mat.m2-define-typography-level(16px, 22px, 400, $letter-spacing: 0.05em);

$metropolis-typography: map.merge(mat.m2-define-typography-config($font-family: '"Metropolis", sans-serif',
      $headline-5: mat.m2-define-typography-level(24px, 33px, 700),
      $headline-6: mat.m2-define-typography-level(18px, 25px, 700, $letter-spacing: 0.05em),
      $subtitle-1: mat.m2-define-typography-level(16px, 22px, 500, $letter-spacing: 0.05em),
      $body-1: mat.m2-define-typography-level(16px, 22px, 400, $letter-spacing: 0.05em),
      $body-2: mat.m2-define-typography-level(16px, 20px, 400, $letter-spacing: 0.05em),
      $subtitle-2: mat.m2-define-typography-level(14px, 20px, 400, $letter-spacing: 0.05em),
      $caption: mat.m2-define-typography-level(12px, 14px, 400, $letter-spacing: 0.05em),
    ),
    ("label": $label,
    ));

$sizes: (
  '0':0rem,'0-1':0.1rem,'0-2':0.2rem,'0-3':0.3rem,'0-4':0.4rem,'0-5':0.5rem,'0-6':0.6rem,'0-7':0.7rem,'0-7-5':0.75rem,'0-8':0.8rem,'0-9':0.9rem,
  '1':1rem,'1-1':1.1rem,'1-2':1.2rem,'1-3':1.3rem,'1-4':1.4rem,'1-5':1.5rem,'1-6':1.6rem,'1-7':1.7rem,'1-8':1.8rem,'1-9':1.9rem,
  '2':2rem,'2-1':2.1rem,'2-2':2.2rem,'2-3':2.3rem,'2-4':2.4rem,'2-5':2.5rem,'2-6':2.6rem,'2-7':2.7rem,'2-8':2.8rem,'2-9':2.9rem,
  '3':3rem,'3-1':3.1rem,'3-2':3.2rem,'3-3':3.3rem,'3-4':3.4rem,'3-5':3.5rem,'3-6':3.6rem,'3-7':3.7rem,'3-8':3.8rem,'3-9':3.9rem,
  '4':4rem,'4-1':4.1rem,'4-2':4.2rem,'4-3':4.3rem,'4-4':4.4rem,'4-5':4.5rem,'4-6':4.6rem,'4-7':4.7rem,'4-8':4.8rem,'4-9':4.9rem,
  '5':5rem,'5-1':5.1rem,'5-2':5.2rem,'5-3':5.3rem,'5-4':5.4rem,'5-5':5.5rem,'5-6':5.6rem,'5-7':5.7rem,'5-8':5.8rem,'5-9':5.9rem,
);
$sizesPercentages: ('10':10%,'20':20%,'30':30%,'40':40%,'50':50%,'60':60%,'70':70%,'80':80%,'90':90%,'100':100%);
    
@include mat.typography-hierarchy($metropolis-typography);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$BayFirst-theme: mat.m2-define-light-theme((color: (primary: $BayFirst-primary,
        accent: $BayFirst-accent,
        warn: $BayFirst-warn,
      ),
      typography: $metropolis-typography));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($BayFirst-theme);

/* You can add global styles to this file, and also import other style files */
@include matStyleOverrides.mat-style-overrides($BayFirst-theme);
@include matStyleOverrides.typography($metropolis-typography);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Metropolis", sans-serif;
}



@include bayfirst.generateColors(primary, palette.$primary);
@include bayfirst.generateColors(accent, palette.$accent);
@include bayfirst.generateColors(warning, palette.$warn);




.text-grey {
  color: #00000099;
}

.text-danger {
  color: #CE0000 !important;
}

.bold {
  font-weight: bold !important;
}

.form-error-div {
  margin-bottom: 10px;
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  padding: 10px;
}

.lock-icon {
  width: 12px !important;
  height: 12px !important;
}

.encriptionMessage{
  margin-top: 24px;
}

@each $sizeName, $size in $sizes {
  .f-#{$sizeName} {
    font-size: $size !important;
  }
  .m-#{$sizeName} {
    margin: $size !important;
  }
  .mx-#{$sizeName} {
    margin-left: $size !important;
    margin-right: $size !important;
  }
  .my-#{$sizeName} {
    margin-top: $size !important;
    margin-bottom: $size !important;
  }
  .ml-#{$sizeName} {
    margin-left: $size !important;
  }
  .mr-#{$sizeName} {
    margin-right: $size !important;
  }
  .mt-#{$sizeName} {
    margin-top: $size !important;
  }
  .mb-#{$sizeName} {
    margin-bottom: $size !important;
  }
  .p-#{$sizeName} {
    padding: $size !important;
  }
  .px-#{$sizeName} {
    padding-left: $size !important;
    padding-right: $size !important;
  }
  .py-#{$sizeName} {
    padding-top: $size !important;
    padding-bottom: $size !important;
  }
  .pl-#{$sizeName} {
    padding-left: $size !important;
  }
  .pr-#{$sizeName} {
    padding-right: $size !important;
  }
  .pt-#{$sizeName} {
    padding-top: $size !important;
  }
  .pb-#{$sizeName} {
    padding-bottom: $size !important;
  }
  .w-#{$sizeName} {
    width: $size !important;
  }
}

@each $sizesPercentageName, $sizesPercentage in $sizesPercentages {
  .w-#{$sizesPercentageName} {
    width: $sizesPercentage !important;
  }
  .h-#{$sizesPercentageName} {
    height: $sizesPercentage !important;
  }
}