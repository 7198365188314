@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $color-config: mat.m2-get-color-config($theme);

  // Get the primary,accent color palette from the color-config.
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
}

@mixin generateColors($prefix, $palette) {
  $colors-map: (
  );

@each $key, $value in $palette {
  @if $key !=contrast {
    .app-#{$prefix}-#{$key} {
      color: map-get($palette, $key);
    }

    $map: (
    );
  $map: map-merge($map, ($key: $value));
  $colors-map: map-merge($colors-map, $map);
}
}

:root {
  @each $key, $value in $colors-map {
    --app-#{$prefix}-#{$key}: #{$value};
    --app-#{$prefix}-#{$key}-rgb: #{red($value)}, #{green($value)}, #{blue($value)};
  }
}
}