@use "sass:map";
@use '@angular/material' as mat;

button.action-button {
    margin-top: 24px;
    font-size: 18px;
    padding: 24px;
    border-radius: 8px;
    min-width: 170px;
}

.mdc-checkbox {
    padding-left: 0 !important;

    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
        background-color: #fff !important;
    }

    .mdc-checkbox__background {
        left: 0 !important;
        border-radius: 4px !important;
        width: 24px !important;
        height: 24px !important;
        --mdc-checkbox-state-layer-size: 34px;
        --mdc-checkbox-unselected-icon-color: #2222227D;
    }

    .mdc-checkbox__ripple,
    .mat-mdc-checkbox-ripple {
        display: none !important;
    }
}

.mat-form-field-override {
    .mat-mdc-form-field {
        .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
            border-color: rgba(255, 255, 255, 0) !important;
        }
        .mat-mdc-text-field-wrapper {
            background-color: rgba(255, 255, 255, 0) !important;
            .mat-mdc-form-field-flex {
                .mat-mdc-form-field-infix {
                    .mat-mdc-input-element {
                        color: black !important;
                    }
                }
            }
        }
    }
}

.mat-radio-grouped {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    .mat-mdc-radio-button {
        &:first-child {
            .mdc-form-field {
                .mdc-radio {
                    padding-left: 10px !important;
                }
            }
        }
    }
}

.mat-mdc-radio-group {
    .mat-mdc-radio-button {
        &:first-child {
            .mdc-form-field {
                .mdc-radio {
                    padding-left: 0;
                }
            }
        }
    }

    .mat-mdc-radio-checked .mdc-radio__background::before,
    .mat-radio-ripple {
        display: none;
    }

    .mdc-form-field {
        label {
            padding-left: 0 !important;
        }
    }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    min-height: 48px;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
}

.mat-mdc-form-field-subscript-wrapper {
    min-height: 24px;

    mat-error {
        display: flex !important;
        align-items: center;
        min-height: 24px;

        .mat-icon {
            margin-right: 0.2rem;
        }
    }
}

@mixin mat-style-overrides($theme) {
    // Get the color config from the theme.
    $color-config: mat.m2-get-color-config($theme);

    // Get the primary color palette from the color-config.
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    // Angular Button Contrast Bug Fix
    .mat-mdc-raised-button.mat-accent,
    .mat-mdc-unelevated-button.mat-accent,
    .mat-mdc-fab.mat-accent,
    .mat-mdc-mini-fab.mat-accent {
        color: mat.m2-get-color-from-palette($accent-palette,
                default-contrast) !important;
        --mat-mdc-button-persistent-ripple-color: mat.m2-get-color-from-palette($accent-palette,
                default-contrast) !important;
        --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
    }

    .mat-mdc-fab.mat-accent,
    .mat-mdc-mini-fab.mat-accent {
        --mdc-fab-icon-color: mat.m2-get-color-from-palette($accent-palette,
                default-contrast);
        --mat-mdc-fab-color: mat.m2-get-color-from-palette($accent-palette,
                default-contrast);
    }

    * {
        --mdc-checkbox-selected-checkmark-color: #fff !important;
    }

    // Radio Group Style Override
    .mat-mdc-radio-group {
        mat-error {
            display: flex !important;
            align-items: center;
            min-height: 24px;
            color: mat.m2-get-color-from-palette($warn-palette, 500) !important;
            font-size: 12px;

            .mat-icon {
                width: 1em;
                margin-right: 0.2rem;
            }
        }

        .mat-mdc-radio-button {
            .mdc-radio {
                .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background {
                    .mdc-radio__outer-circle {
                        border-color: #11111333;
                        background-color: #fff;
                    }
                }

                &:hover {
                    .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background {
                        .mdc-radio__outer-circle {
                            border-color: #11111333;
                            border-width: 2px;
                        }
                    }
                }
            }
        }
    }


    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) {

        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
            border-color: #11111333;
        }

        &:not(.mdc-text-field--focused):not(.mdc-text-field--invalid):hover {
            .mdc-notched-outline {

                .mdc-notched-outline__leading,
                .mdc-notched-outline__notch,
                .mdc-notched-outline__trailing {
                    border-color: #11111333;
                    border-width: 2px;
                }
            }
        }

        &.mdc-text-field--focused {

            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
                border-color: #11111333;
                border-width: 2px;
            }
        }
    }


    // Material Form Invalid Field Color Override
    .mat-form-field-invalid {

        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
            background-color: mat.m2-get-color-from-palette($warn-palette, 50) !important;
        }

        .mat-mdc-form-field-text-prefix,
        .mat-mdc-form-field-infix,
        .mat-mdc-form-field-icon-suffix {
            z-index: 1;
        }

        .mat-mdc-form-field-text-prefix,
        .mdc-text-field__input,
        .mdc-text-field__input::placeholder,
        .mat-mdc-select-placeholder,
        .mat-mdc-form-field-icon-suffix,
        .mat-mdc-form-field-error {
            color: mat.m2-get-color-from-palette($warn-palette, 500) !important;
        }
    }
}

@mixin typography($typography) {

    mat-label {
        margin-bottom: 5px;
    }
}